// Note, this landing page specific functionality should probably be moved with the landing
// core version + navigation, pagination modules:

// @ts-ignore
import Plyr from 'plyr';

const playbackButtonEl = document.getElementById('hear-sample-play');

const createPlyr = (audioElId: string) => {
  const commonOptions = {
    controls: ['play'],
  };
  const audioEl: HTMLElement = document.getElementById(audioElId);
  const player = new Plyr(audioEl, commonOptions);

  // Player event callbacks
  player.on('ended', _ => {
    setPausedState(player, playbackButtonEl.children[0]);
  });

  return player;
};

const setPlayingState = (playerObj, targetEl) => {
  playerObj.play();
  targetEl.classList.remove('fa-play', 'fa-beat');
  targetEl.classList.add('fa-pause');
};

const setPausedState = (playerObj, targetEl) => {
  playerObj.pause();
  targetEl.classList.remove('fa-pause');
  targetEl.classList.add('fa-play');
};

const handlePlaybackEvents = (playbackEl, player) => {

  const publicPreviewPlayEl: HTMLElement = document.getElementById('public-preview');
  publicPreviewPlayEl.addEventListener('click', (event) => {
    if (player.playing) {
      setPausedState(player, playbackButtonEl.children[0]);
    }
  });


  playbackEl.addEventListener('click', (event) => {
    event.preventDefault();
    if (player.playing) {
      setPausedState(player, playbackEl.children[0]);
    } else {
      setPlayingState(player, playbackEl.children[0]);
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  // Activate player control for associated preview samples
  const audioEl = document.getElementById('hear-audio-tag');
  const player = createPlyr(audioEl.id);
  handlePlaybackEvents(playbackButtonEl, player);
});


